export default class Socket {
    constructor(url) {
        this.ws = new WebSocket(url)
        this.ws.onmessage = (event) => {
            const serverEvent = JSON.parse(event.data)
            if (this.eventListeners.has(serverEvent.name)) {
                for (const callback of this.eventListeners.get(serverEvent.name)) {
                    callback(serverEvent.data)
                }
            }
        }
        this.eventListeners = new Map()
    }

    emitEvent(name, data) {
        this.ws.send(JSON.stringify({ name: name, data: data }))
    }
    
    registerEventListener(name, callback) {
        if (this.eventListeners.has(name)) {
            this.eventListeners.get(name).push(callback)
        } else {
            this.eventListeners.set(name, [callback])
        }
    }

    unregisterEventListener(name, callback) {
        this.eventListeners.get(name).splice(this.eventListeners.get(name).indexOf(callback), 1)
    }
    
    close() {
        this.ws.close()
    }
}
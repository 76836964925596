import { useRef, useState, useEffect } from 'react'
import SocketContext from './SocketContext'
import Socket from './Socket'

const SocketProvider = ({ children }) => {
  const socket = useRef(null)
  const [isSocketOpen, setIsSocketOpen] = useState(false)
  
  useEffect(() => {
    socket.current = new Socket(process.env.REACT_APP_API_URL)
    socket.current.registerEventListener('connected', (socketId) => {
      console.log('Connected!')
      socket.current.id = socketId
      console.log(socket.current.id)
      setIsSocketOpen(true)
    })
    return () => socket.current.close()
  }, [])
  
  return (
    <div>
      {isSocketOpen ? (
        <SocketContext.Provider value={socket.current}>{children}</SocketContext.Provider>
      ) : (
        <div>Connecting...</div>
      )}
    </div>
  )
}

export default SocketProvider
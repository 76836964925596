import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import SocketContext from '../SocketContext'
import Modal from '../components/Modal'

const Home = () => {
  const [roomName, setRoomName] = useState('')
  const [roomId, setRoomId] = useState('')
  const [isCreateRoomModalOpen, setIsCreateRoomModalOpen] = useState(false)
  const [isJoinRoomModalOpen, setIsJoinRoomModalOpen] = useState(false)
  
  const socket = useContext(SocketContext)
  
  useEffect(() => {
    socket.registerEventListener('roomCreated', onRoomCreated)
    return () => socket.unregisterEventListener('roomCreated', onRoomCreated)
  }, [])

  const onRoomCreated = (roomId) => navigate(`/room/${roomId}`)
  
  const navigate = useNavigate()
  
  const createRoom = () => {
    if (roomName) {
      socket.emitEvent('createRoom', roomName)
    }
  }
  
  const joinRoom = () => {
    if (roomId) {
      navigate(`/room/${roomId}`)
    }
  }
  
  return (
    <div className="bg-black text-white flex flex-col justify-center items-center h-screen">
      <h1 className="text-xl font-semibold mb-8">Welcome!</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        <button className="bg-[#090909] p-6 rounded-md flex flex-col items-center" onClick={() => setIsCreateRoomModalOpen(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 mb-2" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <rect x={4} y={4} width={16} height={16} rx={2}></rect>
            <line x1={9} y1={12} x2={15} y2={12}></line>
            <line x1={12} y1={9} x2={12} y2={15}></line>
          </svg>
          Create a room
        </button>
        <button className="bg-[#090909] p-6 rounded-md flex flex-col items-center" onClick={() => setIsJoinRoomModalOpen(true)}>
          <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 mb-2" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M13 12v.01"></path>
            <path d="M3 21h18"></path>
            <path d="M5 21v-16a2 2 0 0 1 2 -2h6m4 10.5v7.5"></path>
            <path d="M21 7h-7m3 -3l-3 3l3 3"></path>
          </svg>
          Join a room
        </button>
      </div>
      <Modal isOpen={isCreateRoomModalOpen} setOpen={setIsCreateRoomModalOpen}>
        <div className="flex justify-between items-center p-6">
          <div className="text-lg font-semibold">Create a room</div>
        </div>
        <div className="px-6">
          <input className="bg-[#0e0e0e] px-4 py-2.5 rounded-md" value={roomName} onChange={(event) => setRoomName(event.target.value)} />
        </div>
        <div className="p-6">
          <button className="bg-[#769599] text-black font-semibold px-4 py-2.5 rounded-md" onClick={createRoom}>Create</button>
        </div>
      </Modal>
      <Modal isOpen={isJoinRoomModalOpen} setOpen={setIsJoinRoomModalOpen}>
        <div className="flex justify-between items-center p-6">
          <div className="text-lg font-semibold">Join a room</div>
        </div>
        <div className="px-6">
          <input className="bg-[#0e0e0e] px-4 py-2.5 rounded-md" value={roomId} onChange={(event) => setRoomId(event.target.value)} />
        </div>
        <div className="p-6">
          <button className="bg-[#769599] text-black font-semibold px-4 py-2.5 rounded-md" onClick={joinRoom}>Join</button>
        </div>
      </Modal>
    </div>
  )
}

export default Home
import { useEffect, useState, useRef, useContext, Fragment } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import SocketContext from '../SocketContext'
import Modal from '../components/Modal'

const Room = () => {
  const { id } = useParams()
  
  const [name, setName] = useState(useLocation().state?.name || '')
  const [isJoiningRoom, setIsJoiningRoom] = useState(false)
  const [room, setRoom] = useState(null)
  const roomRef = useRef(null)
  const localStream = useRef(null)
  const [isCameraOn, setIsCameraOn] = useState(false)
  const [isMicOn, setIsMicOn] = useState(false)
  const videoContainer = useRef(null)
  const [videoElementDimensions, setVideoElementDimensions] = useState({ width: 0, height: 0 })
  const [isChatOpen, setIsChatOpen] = useState(true)
  const chatPanel = useRef(null)
  const [chatPanelDimensions, setChatPanelDimensions] = useState({ width: 0, height: 0 })
  const eventsContainer = useRef(null)
  const [autoScrollToBottom, setAutoScrollToBottom] = useState()
  const [events, setEvents] = useState([])
  const [message, setMessage] = useState('')
  const [messageTarget, setMessageTarget] = useState('Room')
  const [isCreateBreakoutRoomsModalOpen, setIsCreateBreakoutRoomsModalOpen] = useState(false)
  const [isManageBreakoutRoomsModalOpen, setIsManageBreakoutRoomsModalOpen] = useState(false)
  const [numberOfBreakoutRooms, setNumberOfBreakoutRooms] = useState(1)
  const [breakoutRooms, setBreakoutRooms] = useState([])
  const [broadcastMessage, setBroadcastMessage] = useState('')
  const [isHelpRequestedModalOpen, setIsHelpRequestedModalOpen] = useState(false)
  const [helpRequest, setHelpRequest] = useState(null)
  
  const socket = useContext(SocketContext)
  
  const navigate = useNavigate()
  
  roomRef.current = room
  
  const updateVideoElementDimensions = () => {
    const computedStyle = window.getComputedStyle(videoContainer.current)
    const gap = parseFloat(computedStyle.gap)
    const containerWidth = videoContainer.current.getBoundingClientRect().width - parseFloat(computedStyle.paddingLeft) - parseFloat(computedStyle.paddingRight)
    const containerHeight = videoContainer.current.getBoundingClientRect().height - parseFloat(computedStyle.paddingTop) - parseFloat(computedStyle.paddingBottom)
    const aspectRatio = 16 / 9
    let optimalWidth = 0
    let optimalHeight = 0
    for (let rows = 1; rows <= room.users.length; rows++) {
      const cols = Math.ceil(room.users.length / rows)
      let width = ((containerWidth + gap) / cols) - gap
      let height = ((containerHeight + gap) / rows) - gap
      if (width / height > aspectRatio) {
        width = height * aspectRatio
      } else {
        height = width / aspectRatio
      }
      const area = width * height
      if (area > optimalWidth * optimalHeight) {
        optimalWidth = width
        optimalHeight = height
      }
    }
    setVideoElementDimensions({ width: optimalWidth, height: optimalHeight })
  }
  
  useEffect(() => {
    if (!videoContainer.current) {
      return
    }
    const observer = new ResizeObserver(updateVideoElementDimensions)
    observer.observe(videoContainer.current)
    const updateChatDimensions = () => {
      if (chatPanel.current) {
        setChatPanelDimensions({ width: chatPanel.current.offsetWidth, height: chatPanel.current.offsetHeight })
      }
    }
    updateChatDimensions()
    window.addEventListener('resize', updateChatDimensions)
    return () => {
      observer.disconnect()
      window.removeEventListener('resize', updateChatDimensions)
    }
  }, [room])
  
  useEffect(() => {
    if (name !== '') {
      joinRoom()
    }
    socket.registerEventListener('roomJoined', onRoomJoined)
    socket.registerEventListener('userJoined', onUserJoined)
    socket.registerEventListener('userLeft', onUserLeft)
    socket.registerEventListener('messageSentToRoom', onMessageSentToRoom)
    socket.registerEventListener('messageSentToSocket', onMessageSentToSocket)
    socket.registerEventListener('breakoutRoomAssigned', onBreakoutRoomAssigned)
    socket.registerEventListener('breakoutRoomsCreated', onBreakoutRoomsCreated)
    socket.registerEventListener('helpRequested', onHelpRequested)
    socket.registerEventListener('offerSentToSocket', onOfferSentToSocket)
    socket.registerEventListener('answerSentToSocket', onAnswerSentToSocket)
    socket.registerEventListener('iceCandidateSentToSocket', onICECandidateSentToSocket)
    return () => {
      socket.unregisterEventListener('roomJoined', onRoomJoined)
      socket.unregisterEventListener('userJoined', onUserJoined)
      socket.unregisterEventListener('userLeft', onUserLeft)
      socket.unregisterEventListener('messageSentToRoom', onMessageSentToRoom)
      socket.unregisterEventListener('messageSentToSocket', onMessageSentToSocket)
      socket.unregisterEventListener('breakoutRoomAssigned', onBreakoutRoomAssigned)
      socket.unregisterEventListener('breakoutRoomsCreated', onBreakoutRoomsCreated)
      socket.unregisterEventListener('helpRequested', onHelpRequested)
      socket.unregisterEventListener('offerSentToSocket', onOfferSentToSocket)
      socket.unregisterEventListener('answerSentToSocket', onAnswerSentToSocket)
      socket.unregisterEventListener('iceCandidateSentToSocket', onICECandidateSentToSocket)
    }
  }, [id])
  
  useEffect(() => {
    if (!eventsContainer.current) {
      return
    }

    if (autoScrollToBottom) {
      eventsContainer.current.scrollTop = eventsContainer.current.scrollHeight
    }
  }, [events])

  const joinRoom = () => {
    setIsJoiningRoom(true)
    socket.emitEvent('joinRoom', { id: id, name: name })
  }
  
  const assignStreamToUser = (id, stream) => {
    setRoom((room) => ({...room, users: room.users.map(user => user.id === id ? {...user, stream: stream} : user)}))
  }
  
  const createAndInitPeerConnection = (id) => {
    const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] }
    const pc = new RTCPeerConnection(configuration)
    pc.ontrack = (event) => {
      assignStreamToUser(id, event.streams[0])
    }
    pc.onicecandidate = (event) => {
      if (event.candidate) {
        socket.emitEvent('sendICECandidateToSocket', { id: id, candidate: event.candidate })
      }
    }
    pc.onconnectionstatechange = () => {
      console.log(pc.connectionState)
    }
    for (const track of localStream.current.getTracks()) {
      pc.addTrack(track, localStream.current)
    }
    return pc
  }
  
  const assignPCToUser = (id, pc) => {
    setRoom((room) => ({...room, users: room.users.map(user => user.id === id ? {...user, pc: pc} : user)}))
  }
  
  const silence = () => {
    const ctx = new AudioContext(), oscillator = ctx.createOscillator()
    const dst = oscillator.connect(ctx.createMediaStreamDestination())
    oscillator.start()
    return Object.assign(dst.stream.getAudioTracks()[0], {enabled: false})
  }

  const black = ({width = 640, height = 480} = {}) => {
    const canvas = Object.assign(document.createElement("canvas"), {width, height})
    canvas.getContext('2d').fillRect(0, 0, width, height)
    const stream = canvas.captureStream()
    return Object.assign(stream.getVideoTracks()[0], {enabled: false})
  }
  
  const onRoomJoined = async (room) => {
    setIsJoiningRoom(false)
    setRoom(room)
    const blackSilence = new MediaStream([black(), silence()])
    localStream.current = blackSilence
    for (const user of room.users.filter(x => x.id !== socket.id)) {
      const pc = createAndInitPeerConnection(user.id)
      assignPCToUser(user.id, pc)
      const offer = await pc.createOffer({ offerToReceiveVideo: true, offerToReceiveAudio: true })
      await pc.setLocalDescription(offer)
      socket.emitEvent('sendOfferToSocket', { id: user.id, offer: offer })
    }
  }
  
  const onUserJoined = (user) => {
    setRoom((room) => ({...room, users: [...room.users, user]}))
    setEvents((events) => [...events, { name: 'userJoined', data: user }])
    setAutoScrollToBottom(eventsContainer.current.scrollTop + eventsContainer.current.offsetHeight === eventsContainer.current.scrollHeight)
  }

  const onUserLeft = (user) => {
    setRoom((room) => ({...room, users: room.users.filter(x => x.id !== user.id)}))
    setEvents((events) => [...events, { name: 'userLeft', data: user }])
    setAutoScrollToBottom(eventsContainer.current.scrollTop + eventsContainer.current.offsetHeight === eventsContainer.current.scrollHeight)
  }

  const onMessageSentToRoom = (data) => {
    setEvents((events) => [...events, { name: 'messageSentToRoom', data: data }])
    setAutoScrollToBottom(eventsContainer.current.scrollTop + eventsContainer.current.offsetHeight === eventsContainer.current.scrollHeight)
  }

  const onMessageSentToSocket = (data) => {
    setEvents((events) => [...events, { name: 'messageSentToSocket', data: data }])
    setAutoScrollToBottom(eventsContainer.current.scrollTop + eventsContainer.current.offsetHeight === eventsContainer.current.scrollHeight)
  }
  
  const onBreakoutRoomAssigned = (roomId) => {
    socket.emitEvent('leaveRoom', id)
    navigate(`/room/${roomId}`)
  }

  const onBreakoutRoomsCreated = (roomIds) => {
    setBreakoutRooms(roomIds)
    setIsCreateBreakoutRoomsModalOpen(false)
  }

  const onHelpRequested = (helpRequest) => {
    setHelpRequest(helpRequest)
    setIsHelpRequestedModalOpen(true)
  }

  const onOfferSentToSocket = async ({ id, offer }) => {
    const pc = createAndInitPeerConnection(id)
    assignPCToUser(id, pc)
    await pc.setRemoteDescription(new RTCSessionDescription(offer))
    const answer = await pc.createAnswer()
    await pc.setLocalDescription(answer)
    socket.emitEvent('sendAnswerToSocket', { id: id, answer: answer })
  }

  const onAnswerSentToSocket = async ({ id, answer }) => {
    const pc = roomRef.current.users.find(x => x.id === id).pc
    await pc.setRemoteDescription(new RTCSessionDescription(answer))
  }

  const onICECandidateSentToSocket = async ({ id, candidate }) => {
    const pc = roomRef.current.users.find(x => x.id === id).pc
    await pc.addIceCandidate(new RTCIceCandidate(candidate))
  }
  
  const acceptHelpRequest = () => {
    setIsHelpRequestedModalOpen(false)
    socket.emitEvent('leaveRoom', id)
    navigate(`/room/${helpRequest.id}`)
  }
  
  const sendMessage = () => {
    if (!message) {
      return
    }
    
    if (messageTarget === 'Room') {
      socket.emitEvent('sendMessageToRoom', { id: id, message: message })
      setEvents((events) => [...events, { name: 'messageSentToRoom', data: { sender: { id: socket.id, name: name }, content: message, sentAt: Date.now() } }])
    } else {
      socket.emitEvent('sendMessageToSocket', { id: messageTarget, message: message })
      setEvents((events) => [...events, { name: 'messageSentToSocket', data: { sender: { id: socket.id, name: name }, content: message, sentAt: Date.now() } }])
    }
    setMessage('')
  }

  const toggleCamera = async () => {
    let videoTrack
    if (!isCameraOn) {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      videoTrack = stream.getVideoTracks()[0]
    } else {
      videoTrack = black()
    }
    setIsCameraOn(!isCameraOn)
    const oldVideoTrack = localStream.current.getVideoTracks()[0]
    oldVideoTrack.stop()
    localStream.current.removeTrack(oldVideoTrack)
    localStream.current.addTrack(videoTrack)
    for (const user of room.users.filter(x => x.id !== socket.id)) {
      const sender = user.pc.getSenders().find((s) => s.track.kind === videoTrack.kind)
      sender.replaceTrack(videoTrack)
    }
  }

  const toggleMic = async () => {
    let audioTrack
    if (!isMicOn) {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      audioTrack = stream.getAudioTracks()[0]
    } else {
      audioTrack = silence()
    }
    setIsMicOn(!isMicOn)
    const oldAudioTrack = localStream.current.getAudioTracks()[0]
    oldAudioTrack.stop()
    localStream.current.removeTrack(oldAudioTrack)
    localStream.current.addTrack(audioTrack)
    for (const user of room.users.filter(x => x.id !== socket.id)) {
      const sender = user.pc.getSenders().find((s) => s.track.kind === audioTrack.kind)
      sender.replaceTrack(audioTrack)
    }
  }
  
  const openBreakoutRoomModal = () => {
    if (breakoutRooms.length > 0) {
      setIsManageBreakoutRoomsModalOpen(true)
    } else {
      setIsCreateBreakoutRoomsModalOpen(true)
    }
  }

  const createBreakoutRooms = () => {
    socket.emitEvent('createBreakoutRooms', { parentRoomId: id, number: numberOfBreakoutRooms })
  }

  const broadcastToBreakoutRooms = () => {
    if (!broadcastMessage) {
      return
    }
    
    for (const roomId of breakoutRooms) {
      socket.emitEvent('sendMessageToRoom', { id: roomId, message: broadcastMessage })
    }
    setBroadcastMessage('')
  }

  const requestHelp = () => {
    socket.emitEvent('requestHelp', id)
  }
  
  const leave = () => {
    socket.emitEvent('leaveRoom', id)
    if (room.parentRoom) {
      navigate(`/room/${room.parentRoom}`)
    } else {
      navigate('/')
    }
  }
  
  return (
    <>
      {!room ? (
        <>
          {!isJoiningRoom ? (
            <div className="bg-[#090909] text-white h-screen flex justify-center items-center">
              <div>
                <div className="text-lg font-semibold text-center mb-8">What's your name?</div>
                <input className="bg-[#0e0e0e] px-4 py-2.5 rounded-md mr-4" value={name} onChange={(event) => setName(event.target.value)} />
                <button className="bg-[#769599] text-black font-semibold px-4 py-2.5 rounded-md" onClick={joinRoom}>Join</button>
              </div>
            </div>
          ) : (
            <div>Joining room...</div>
          )}
        </>
      ) : (
        <div className="flex flex-col sm:flex-row h-screen text-white bg-[#090909] overflow-hidden">
          <div className="flex-1 flex justify-center content-center flex-wrap gap-4 p-6 bg-black relative rounded-b-3xl sm:rounded-b-none overflow-hidden" ref={videoContainer}>
            {room.users.map((user, index) => (
              <div className="relative rounded-md overflow-hidden bg-[#090909]" style={{ width: videoElementDimensions.width, height: videoElementDimensions.height }} key={index}>
                <video className="w-full h-full object-contain -scale-x-100" autoPlay muted={user.id === socket.id} ref={(ref) => { if (ref && ref.srcObject !== ((user.id === socket.id) ? localStream.current : user.stream)) ref.srcObject = (user.id === socket.id) ? localStream.current : user.stream }}></video>
                <div className="absolute bottom-2 left-2 bg-black/50 px-2 py-0.5 rounded">{user.name}</div>
              </div>
            ))}
            <div className="p-6 flex justify-between items-center absolute top-0 inset-x-0 bg-gradient-to-b from-black">
              <div className="text-xl font-semibold">{room.name}</div>
              <button className="bg-black/50 p-2.5 rounded-md" onClick={leave}>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M13 12v.01"></path>
                  <path d="M3 21h18"></path>
                  <path d="M5 21v-16a2 2 0 0 1 2 -2h7.5m2.5 10.5v7.5"></path>
                  <path d="M14 7h7m-3 -3l3 3l-3 3"></path>
                </svg>
              </button>
            </div>
            <div className="p-6 flex absolute bottom-0 inset-x-0 bg-gradient-to-t from-black">
              <div className="flex-1 flex gap-4">
                <button className={`p-2.5 rounded-md transition-colors ${(isCameraOn) ? 'bg-white text-black' : 'bg-black/50'}`} onClick={toggleCamera}>
                  {isCameraOn ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M15 10l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -1.447 .894l-4.553 -2.276v-4z" />
                      <path d="M3 6m0 2a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2z" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 3l18 18" />
                      <path d="M15 11v-1l4.553 -2.276a1 1 0 0 1 1.447 .894v6.764a1 1 0 0 1 -.675 .946" />
                      <path d="M10 6h3a2 2 0 0 1 2 2v3m0 4v1a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h1" />
                    </svg>
                  )}
                </button>
                <button className={`p-2.5 rounded-md transition-colors ${(isMicOn) ? 'bg-white text-black' : 'bg-black/50'}`} onClick={toggleMic}>
                  {isMicOn ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M9 2m0 3a3 3 0 0 1 3 -3h0a3 3 0 0 1 3 3v5a3 3 0 0 1 -3 3h0a3 3 0 0 1 -3 -3z" />
                      <path d="M5 10a7 7 0 0 0 14 0" />
                      <path d="M8 21l8 0" />
                      <path d="M12 17l0 4" />
                    </svg>
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 3l18 18" />
                      <path d="M9 5a3 3 0 0 1 6 0v5a3 3 0 0 1 -.13 .874m-2 2a3 3 0 0 1 -3.87 -2.872v-1" />
                      <path d="M5 10a7 7 0 0 0 10.846 5.85m2 -2a6.967 6.967 0 0 0 1.152 -3.85" />
                      <path d="M8 21l8 0" />
                      <path d="M12 17l0 4" />
                    </svg>
                  )}
                </button>
              </div>
              <div className="flex-1 flex justify-center">
                {socket.id === room.host && (
                  <button className="bg-black/50 p-2.5 rounded-md" onClick={openBreakoutRoomModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <rect x={4} y={4} width={6} height={6} rx={1}></rect>
                      <rect x={14} y={4} width={6} height={6} rx={1}></rect>
                      <rect x={4} y={14} width={6} height={6} rx={1}></rect>
                      <rect x={14} y={14} width={6} height={6} rx={1}></rect>
                    </svg>
                  </button>
                )}
                {socket.id !== room.host && room.parentRoom && (
                  <button className="bg-black/50 p-2.5 rounded-md" onClick={requestHelp}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M8 13v-7.5a1.5 1.5 0 0 1 3 0v6.5"></path>
                      <path d="M11 5.5v-2a1.5 1.5 0 1 1 3 0v8.5"></path>
                      <path d="M14 5.5a1.5 1.5 0 0 1 3 0v6.5"></path>
                      <path d="M17 7.5a1.5 1.5 0 0 1 3 0v8.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7a69.74 69.74 0 0 1 -.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"></path>
                    </svg>
                  </button>
                )}
              </div>
              <div className="flex-1 flex justify-end">
                <button className={`p-2.5 rounded-md transition-colors ${(isChatOpen) ? 'bg-white text-black' : 'bg-black/50'}`} onClick={() => setIsChatOpen(!isChatOpen)}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 9h8" />
                    <path d="M8 13h6" />
                    <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className={`h-2/5 sm:h-auto sm:w-1/3 sm:max-w-[32rem] flex flex-col transition-[margin] ${(!isChatOpen) ? 'mb-[calc(var(--height)*-1)] sm:mb-0 sm:mr-[calc(var(--width)*-1)]' : ''}`} style={{ '--width': chatPanelDimensions.width + 'px', '--height': chatPanelDimensions.height + 'px' }} ref={chatPanel}>
            <div className="flex-1 p-6 overflow-y-auto" ref={eventsContainer}>
              {events.map((event, index) => (
                <Fragment key={index}>
                  {(() => {
                    switch (event.name) {
                      case 'userJoined':
                        return (
                          <div className="text-center mb-4 last:mb-0">{event.data.name} joined</div>
                        )
                      case 'userLeft':
                        return (
                          <div className="text-center mb-4 last:mb-0">{event.data.name} left</div>
                        )
                      case 'messageSentToRoom':
                      case 'messageSentToSocket':
                        return (
                          <div className={`mb-4 last:mb-0 flex flex-col ${event.data.sender.id === socket.id ? 'items-end' : 'items-start'}`}>
                            <div className="flex mb-2">
                              <div className="mr-2">{event.data.sender.id === socket.id ? 'You' : event.data.sender.name}</div>
                              <div className="text-gray-500 mr-2">{new Date(event.data.sentAt).toLocaleTimeString([], { timeStyle: 'short' })}</div>
                              {event.name === 'messageSentToSocket' && <div className="text-red-500">(Private message)</div>}
                            </div>
                            <div className={`p-4 rounded-md w-fit ${event.data.sender.id === socket.id ? 'bg-[#769599] text-black rounded-tr-none' : 'bg-[#0e0e0e] rounded-tl-none'}`} style={{ overflowWrap: 'anywhere' }}>{event.data.content}</div>
                          </div>
                        )
                      default:
                        return null
                    }
                  })()}
                </Fragment>
              ))}
            </div>
            <div className="p-6">
              <select className="bg-[#0e0e0e] px-4 py-2.5 rounded-md mb-4" value={messageTarget} onChange={(event) => setMessageTarget(event.target.value)}>
                <option value="Room">Room</option>
                {room.users.filter(x => x.id !== socket.id).map((user, index) => (
                  <option value={user.id} key={index}>{user.name}</option>
                ))}
              </select>
              <div className="flex">
                <input className="bg-[#0e0e0e] px-4 py-2.5 rounded-md flex-1 min-w-0 mr-4" value={message} onChange={(event) => setMessage(event.target.value)} />
                <button className="bg-[#769599] text-black p-2.5 rounded-md" onClick={sendMessage}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" width={24} height={24} viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M10 14l11 -11" />
                    <path d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <Modal isOpen={isCreateBreakoutRoomsModalOpen} setOpen={setIsCreateBreakoutRoomsModalOpen}>
            <div className="flex justify-between items-center p-6">
              <div className="text-lg font-semibold">Create breakout rooms</div>
            </div>
            <div className="px-6">
              <input className="bg-[#0e0e0e] px-4 py-2.5 rounded-md" type="number" min="1" value={numberOfBreakoutRooms} onChange={(event) => setNumberOfBreakoutRooms(event.target.value)} />
            </div>
            <div className="p-6">
              <button className="bg-[#769599] text-black font-semibold px-4 py-2.5 rounded-md" onClick={createBreakoutRooms}>Create</button>
            </div>
          </Modal>
          <Modal isOpen={isManageBreakoutRoomsModalOpen} setOpen={setIsManageBreakoutRoomsModalOpen}>
            <div className="flex justify-between items-center p-6">
              <div className="text-lg font-semibold">Broadcast to breakout rooms</div>
            </div>
            <div className="px-6">
              <input className="bg-[#0e0e0e] px-4 py-2.5 rounded-md" value={broadcastMessage} onChange={(event) => setBroadcastMessage(event.target.value)} />
            </div>
            <div className="p-6">
              <button className="bg-[#769599] text-black font-semibold px-4 py-2.5 rounded-md" onClick={broadcastToBreakoutRooms}>Broadcast</button>
            </div>
          </Modal>
          <Modal isOpen={isHelpRequestedModalOpen} setOpen={setIsHelpRequestedModalOpen}>
            <div className="flex justify-between items-center p-6">
              <div className="text-lg font-semibold">Help requested</div>
            </div>
            <div className="px-6">
              <p>{helpRequest?.requester.name} needs your help!</p>
            </div>
            <div className="p-6">
              <button className="bg-[#769599] text-black font-semibold px-4 py-2.5 rounded-md" onClick={acceptHelpRequest}>Join</button>
            </div>
          </Modal>
        </div>
      )}
    </>
  )
}

export default Room
const Modal = ({ isOpen, setOpen, children }) => {
  if (!isOpen) {
    return null
  }
  
  return (
    <div>
      <div className="bg-black opacity-50 fixed inset-0" onClick={() => setOpen(false)}></div>
      <div className="fixed inset-0 flex justify-center items-center pointer-events-none">
        <div className="bg-[#090909] rounded-md pointer-events-auto">{children}</div>
      </div>
    </div>
  )
}

export default Modal